import React, { Fragment } from 'react'
import Seo from '../components/Seo'
import styled from '@emotion/styled'
import { typography, colors, mediaQueries as mq } from 'src/styles'
import Container from 'src/components/Container'
import Shape from 'src/svg/shape-hexagon.svg'
import Parallax from 'src/components/Parallax'

const PageWrapper = styled.div`
  position: relative;
  background-color: ${colors.greenLighten};
  color: ${colors.green};
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
const Heading1 = styled.h1`
  font-size: ${typography.fontSizeExtraLarge5};
  margin: 0 auto;
  font-weight: 700;

  ${ mq.largerAndUp } {
    font-size: ${typography.fontSizeExtraLarge6};
  }
`
const Heading2 = styled.h2`
  font-size: ${typography.fontSizeExtraLarge2};
  margin: 0 auto 6px;
  text-transform: uppercase;
  font-weight: 700;
`
const ShortRule = styled.hr `
  width: 40px;
  border-color: currentColor;
  border-width: 5px;
  text-align: left;
  margin: 40px auto;

  ${ mq.largeAndUp } {
    width: 60px;
    margin: 24px auto;
  }
`
const Paragraph = styled.p`
  margin: 0 auto;
  font-size: ${typography.fontSizeExtraLarge2};
  line-height: ${typography.leadingSnug};
`
const BgShapeWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-20%, -40%);
`
const BgShape = styled(Shape)`
  width: 330px;
  color: ${colors.white};
`
export default function NotFoundPage(){

  return (
    <Fragment>
      <Seo title="404" />
      <PageWrapper style={{ overflow: 'hidden' }}>
        <BgShapeWrapper>
          <Parallax rotateStart={30} rotateEnd={-135} posYStart={50} posYEnd={-50} scrollUnit="%">
            <BgShape />
          </Parallax>
        </BgShapeWrapper>
        <Container style={{ position: 'relative' }}>
          <Heading1>404</Heading1>
          <ShortRule />
          <Heading2>Page not found</Heading2>
          <Paragraph>The page you were looking for doesn't exist. You may have mistyped the address or the page may have moved.</Paragraph>
        </Container>
      </PageWrapper>
    </Fragment>
  )
}
